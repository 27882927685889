import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../sass/main.scss"
import ProjectCardContainer from "../components/ProjectCardContainer"

const Project = ({ title, description, links, loaded }) => {
  return (
    <div
      className={`cell small-12 medium-6 large-4 paper project ${
        loaded ? "loaded" : ""
      }`}
    >
      <span className="title">{title}</span>
      <span className="content">
        <p>{description}</p>
        {links && (
          <span className="icons-container">
            {links.map(link => {
              return (
                <a
                  key={link.link}
                  href={link.link}
                  rel="noreferrer"
                  target="__blank"
                >
                  {/* {icons[link.name]} */}
                </a>
              )
            })}
          </span>
        )}
      </span>
    </div>
  )
}

const ProjectsPage = ({ data: { allProjectsJson: projects } }) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true)
    }, 16)
    return () => {
      setLoaded(false)
      clearTimeout(timer)
    }
  }, [])
  return (
    <Layout className="favourites">
      <SEO title="Projects" />
      <div className="grid-container">
        <div className="projects">
          {projects.edges.map(({ node }) => {
            return (
              <ProjectCardContainer
                project={node}
                key={node.id}
                title={node.title}
                description={node.description}
                links={node.links}
                loaded={loaded}
              />
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query projects {
    allProjectsJson {
      edges {
        node {
          id
          links {
            link
            name
          }
          title
          description
          date(formatString: "DD MMM, YYYY.")
        }
      }
    }
  }
`

export default ProjectsPage

import React from "react"
import ImageWithFallback from "./ImageWithFallback"
import { FiGithub, FiGlobe, FiInfo } from "react-icons/fi"
import "../sass/main.scss"

const icons = {
  Github: <FiGithub />,
  Demo: <FiGlobe />,
  Blog: <FiInfo />,
}

const ProjectCard = ({
  screenshot,
  date,
  title,
  excerpt,
  description,
  links,
  technologies,
}) => (
  <div className="project-card">
    {/* <ImageWithFallback src={screenshot} alt={title} /> */}
    <div className="content">
      <h2>{title}</h2>
      {/* <p className="date">{date}</p> */}
      <p className="excerpt">{excerpt}</p>
      <p>{description}</p>
      {technologies && (
        <ul className="technologies">
          {technologies.map(tech => (
            <li key={tech}>{tech}</li>
          ))}
        </ul>
      )}
      <div className="links">
        {links.map(link => (
          <a
            key={link.name}
            href={link.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {icons[link.name]}
          </a>
        ))}
      </div>
    </div>
  </div>
)

export default ProjectCard

import React from "react"
import ProjectCard from "./ProjectCard"

const ProjectCardContainer = ({ project }) => {
  const formatDate = dateString => new Date(dateString).toLocaleDateString()
  const {
    screenshot,
    date,
    title,
    excerpt,
    description,
    links,
    technologies,
  } = project

  return (
    <ProjectCard
      screenshot={screenshot}
      date={formatDate(date)}
      title={title}
      excerpt={excerpt}
      description={description}
      links={links}
      technologies={technologies}
    />
  )
}

export default ProjectCardContainer
